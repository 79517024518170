import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { MachineDetailsPanelRobotActions } from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import * as machineDetailsPanelRobotSelectors from '../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineRobotCleaningTaskReport } from './MachineRobotCleaningTaskReport.styles';
import { MachineDetailsRobotCtrList } from './MachineDetailsRobotCtrList/MachineDetailsRobotCtrList';
import { MachineDetailsRobotRouteImage } from './MachineDetailsRobotRouteImage/MachineDetailsRobotRouteImage';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ShortInfo } from 'lib/components/ShortInfo/ShortInfo';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { OpenSearch } from 'config/constants';
import { RobotUtils } from 'app/utils/robot/RobotUtils';

interface MachineRobotCleaningTaskReportProps {
  robot: Machine;
}

export const MachineRobotCleaningTaskReport = ({ robot }: MachineRobotCleaningTaskReportProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const robotsKPIsData = useSelector(machineDetailsPanelRobotSelectors.selectRobotCleaningKPIs);
  const isRobotsKPIsLoading = useSelector(machineDetailsPanelRobotSelectors.selectRobotCleaningKPIsIsLoading);
  const robotsCleaningConsumptionSummaryData = useSelector(
    machineDetailsPanelRobotSelectors.selectRobotCleaningConsumptionSummary
  );
  const isRobotsCleaningConsumptionSummaryLoading = useSelector(
    machineDetailsPanelRobotSelectors.selectRobotCleaningConsumptionSummaryIsLoading
  );

  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeNameActive = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningConsumptionSummaryRequest({
        filter: {
          machineIds: [robot.id || ''],
          period: {
            endAt: endDate,
            startAt: startDate,
          },
          routeName: routeNameActive,
        },
      })
    );
  }, [robot.id, dispatch, endDate, startDate, routeNameActive]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotCleaningKPIsRequest({
        filter: {
          period: {
            endAt: endDate,
            startAt: startDate,
          },
          machineIds: [robot.id || ''],
          routeName: routeNameActive,
        },
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
      })
    );
  }, [dispatch, endDate, robot.id, startDate, routeNameActive]);

  // KPI present UI for kiraB50
  const kiraB50KPIpresent = (): ReactNode => (
    <>
      <Spin spinning={!!isRobotsKPIsLoading}>
        <Row gutter={[16, 16]} className="machine-robot-cleaning-task-report__statistic-row">
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="machine-robot-cleaning-task-report__short-info"
              icon={<SvgIcon name="wallClock" />}
              title={t('robotDashboard.robotKpis.infos.cleanHrs')}
              value={Numbers.formatNumberDecimalRounded(robotsKPIsData?.totalCleaningHrs || 0, 1)}
              unit={t('common.hUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="machine-robot-cleaning-task-report__short-info"
              icon={<SvgIcon name="clean" />}
              title={t('robotDashboard.robotKpis.infos.cleanedArea')}
              value={robotsKPIsData?.totalCleanedArea || 0}
              unit={t('common.areaUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="machine-robot-cleaning-task-report__short-info"
              icon={<SvgIcon name="distance" />}
              title={t('robotDashboard.robotKpis.infos.distance')}
              value={Numbers.formatNumberDecimalRounded((robotsKPIsData?.distanceDriven || 0) / 1000, 1)}
              unit={t('common.kmUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="machine-robot-cleaning-task-report__short-info"
              icon={<SvgIcon name="coverage" />}
              title={t('robotDashboard.robotKpis.infos.coverage')}
              value={Math.round((robotsKPIsData?.taskCoverage || 0) * 100)}
              unit={t('common.percentUnit')}
            />
          </Col>
        </Row>
      </Spin>

      <Spin spinning={!!isRobotsCleaningConsumptionSummaryLoading}>
        <Row gutter={[16, 16]} className="machine-robot-cleaning-task-report__statistic-row">
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="machine-robot-cleaning-task-report__short-info"
              icon={<SvgIcon name="successRace" />}
              title={t('robotDashboard.robotKpis.infos.taskCompleted')}
              value={Math.round(robotsKPIsData?.tasksCompleted || 0)}
              unit={t('common.percentUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="robot-cleaning-consumption-bar__short-info"
              icon={<SvgIcon name="battery" />}
              title={t('machineDetails.cleaningTaskOverviewRobot.cleaningConsumption.battery')}
              value={Numbers.formatNumberDecimalRounded(robotsCleaningConsumptionSummaryData?.battery || 0, 1)}
              unit={t('common.kWhUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="robot-cleaning-consumption-bar__short-info"
              icon={<SvgIcon name="waterAmountDouble" />}
              title={t('machineDetails.cleaningTaskOverviewRobot.cleaningConsumption.water')}
              value={Numbers.formatNumberDecimalRounded(robotsCleaningConsumptionSummaryData?.water || 0, 1)}
              unit={t('common.litreUnit')}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <ShortInfo
              className="robot-cleaning-consumption-bar__short-info"
              icon={<SvgIcon name="containerDetergent" />}
              title={t('machineDetails.cleaningTaskOverviewRobot.cleaningConsumption.detergents')}
              value={Numbers.formatNumberDecimalRounded(robotsCleaningConsumptionSummaryData?.detergents || 0, 1)}
              unit={t('common.litreUnit')}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );

  // KPI present UI for CV50
  const cv50KPIpresent = (): ReactNode => (
    <Spin spinning={!!isRobotsKPIsLoading}>
      <Row gutter={[12, 32]} className="machine-robot-cleaning-task-report__statistic-row">
        <Col span={8}>
          <ShortInfo
            className="machine-robot-cleaning-task-report__short-info"
            icon={<SvgIcon name="wallClock" />}
            title={t('robotDashboard.robotKpis.infos.cleanHrs')}
            value={Numbers.formatNumberDecimalRounded(robotsKPIsData?.totalCleaningHrs || 0, 1)}
            unit={t('common.hUnit')}
          />
        </Col>
        <Col span={8}>
          <ShortInfo
            className="machine-robot-cleaning-task-report__short-info"
            icon={<SvgIcon name="clean" />}
            title={t('robotDashboard.robotKpis.infos.cleanedArea')}
            value={robotsKPIsData?.totalCleanedArea || 0}
            unit={t('common.areaUnit')}
          />
        </Col>
        <Col span={8}>
          <ShortInfo
            className="machine-robot-cleaning-task-report__short-info"
            icon={<SvgIcon name="successRace" />}
            title={t('robotDashboard.robotKpis.infos.taskCompleted')}
            value={Math.round(robotsKPIsData?.tasksCompleted || 0)}
            unit={t('common.percentUnit')}
          />
        </Col>
      </Row>
    </Spin>
  );

  return (
    <StyledMachineRobotCleaningTaskReport className="machine-robot-cleaning-task-report">
      <h3 tabIndex={0} className="machine-robot-cleaning-task-report__header">
        {t('machineDetails.generalInfo.cleaningTaskOverviewRobot')}
      </h3>

      {RobotUtils.getRobotType(robot) === 'B50' ? kiraB50KPIpresent() : cv50KPIpresent()}

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <MachineDetailsRobotCtrList robot={robot} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <MachineDetailsRobotRouteImage />
        </Col>
      </Row>
    </StyledMachineRobotCleaningTaskReport>
  );
};
